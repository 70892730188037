import isString from 'lodash/isString'
import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import findIndex from 'lodash/findIndex'

export default {
  props: {
    itemIdentifier: {
      type: [String, Array, Function],
      default: 'id'
    }
  },
  methods: {
    itemGetValue (item) {
      if (isString(this.itemIdentifier)) {
        return item[this.itemIdentifier]
      } else if (isArray(this.itemIdentifier) && this.itemIdentifier.length > 0) {
        const val = pick(item, this.itemIdentifier)
        return Object.keys(val).length !== this.itemIdentifier.length ? undefined : val
      } else if (isFunction(this.itemIdentifier)) {
        return this.itemIdentifier(item)
      }

      return undefined
    },
    itemIsEqual (itemA, itemB) {
      return isEqual(this.itemGetValue(itemA), this.itemGetValue(itemB))
    },
    itemFindIndex (items, item) {
      return findIndex(items, el => this.itemIsEqual(el, item))
    },
    itemKey (item) {
      const val = this.itemGetValue(item)

      if (isObject(val)) {
        return Object.values(val).join('-')
      }

      return val.toString()
    }
  }
}
