<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import Itemable from 'mixins/itemable'
import ObjectListView from 'data-views/object-list-view.vue'
import ObjectsSelectCardMenu from './objects-select-card-menu.vue'
import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import pick from 'lodash/pick'
import castArray from 'lodash/castArray'

export default {
  name: 'ObjectsControl',
  components: { ObjectListView, ObjectsSelectCardMenu },
  mixins: [Requestable, Cacheable, Controlable, Itemable],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...requestablePropFactory('updateRequestParameter').props,
    ...requestablePropFactory('listRequestParameter').props,
    ...Controlable.props,
    ...Itemable.props,
    ...pick(ObjectsSelectCardMenu.props, ['additionalItems']),
    label: { type: String, default: undefined },
    icon: { type: String, default: undefined },
    value: { type: [Object, Array], default: undefined },
    queryMaxResults: {
      type: Number,
      default: 10
    },
    single: Boolean,
    disabled: Boolean,
    hint: {
      type: String,
      default: undefined
    },
    persistentHint: Boolean,
    indent: Boolean,
    sidebar: Boolean,
    outlined: Boolean,
    divider: {
      type: Boolean,
      default: true
    },
    selectCardMenuAttach: {
      type: [Element, Object, String, Boolean],
      default: false
    },
    selectMenuCardNudgeBottomDifference: {
      type: Number,
      default: 0
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    changeButtonTooltip: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      menuOpen: false
    }
  },
  computed: {
    controlUpdateRequestParameter () {
      return this.updateRequestParameter
    },
    cachedValueChanged () {
      return this.$refs.selectionMenu.cachedValueChanged
    },
    showValues () {
      const valueAsArray = !this.value ? [] : castArray(this.value)
      const cacheValueAsArray = !this.cacheValue ? [] : castArray(this.cacheValue)

      if (this.requestableLoading || this.hasError) {
        // copy all items and set skeleton true for deleted
        const newValues = valueAsArray.map((item) => {
          const itemCopy = cloneDeep(item)
          itemCopy.skeleton = (this.itemFindIndex(this.cacheValue, item) === -1)
          return itemCopy
        })

        if (!this.single || newValues.length === 0) {
          // add all added items
          forEach(this.cacheValue, (item) => {
            if (this.itemFindIndex(this.value, item) === -1) {
              const itemCopy = cloneDeep(item)
              itemCopy.skeleton = true
              newValues.push(itemCopy)
            }
          })
        }
        return newValues
      } else {
        return cacheValueAsArray
      }
    }
  },
  methods: {
    createCacheValue () {
      if (this.value) {
        this.cacheValue = cloneDeep(this.value)
        if (!isArray(this.cacheValue)) {
          this.cacheValue = [this.cacheValue]
        }
      } else {
        this.cacheValue = []
      }
    },
    onSelectionChanged (selectedItems) {
      this.cacheValue = selectedItems

      const requestValue = this.single ? (this.cacheValue.length === 0 ? null : this.itemGetValue(this.cacheValue[0])) : map(this.cacheValue, this.itemGetValue)
      const emitValue = this.single ? (this.cacheValue.length === 0 ? null : this.cacheValue[0]) : this.cacheValue

      this.controlOnChange(requestValue, emitValue)
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()

      if (this.single) {
        this.$emit('change', this.cacheValue.length === 0 ? null : this.cacheValue[0])
      } else {
        this.$emit('change', this.cacheValue)
      }

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>
<template>
  <v-input
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :error-messages="controlErrorMessages"
    :error-count="Number.MAX_VALUE"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    :class="[controlClass, {'custom-object-control--sidebar' : sidebar}]"
    class="custom-object-control"
  >
    <v-card
      ref="container"
      :flat="!outlined"
      :outlined="outlined"
    >
      <objects-select-card-menu
        ref="selectionMenu"
        v-model="menuOpen"
        :attach="selectCardMenuAttach || $refs.container"
        :nudge-bottom="(sidebar ? 36 : 52) + selectMenuCardNudgeBottomDifference"
        :request-parameter="listRequestParameter"
        :query-max-results="queryMaxResults"
        :additional-items="additionalItems"
        :single="single"
        :allow-empty="allowEmpty"
        :value="cacheValue"
        :item-identifier="itemIdentifier"
        close-on-click
        @change="onSelectionChanged"
        @opened="controlOnLock"
        @cancel="controlOnUnlock"
      >
        <template #list-item="{_item, _cssClass, _itemClass, _indent, _selectable, _selected, _toggleElement}">
          <slot
            name="list-item"
            :item="_item"
            :css-class="_cssClass"
            :item-class="_itemClass"
            :indent="_indent"
            :selectable="_selectable"
            :selected="_selected"
            :toggle-element="_toggleElement"
          />
        </template>

        <template
          v-if="$scopedSlots['create-action']"
          #create-action="{onCreated}"
        >
          <slot
            name="create-action"
            :on-created="onCreated"
          />
        </template>
      </objects-select-card-menu>

      <v-card-title
        :class="[indent ? 'px-4': 'px-0', sidebar ? 'pt-2 pb-0 pr-2' : 'py-3', {'pb-2': sidebar && showValues.length === 0}]"
        class="flex-nowrap"
      >
        <v-icon
          v-if="icon"
          :class="[hasError ? 'error--text': 'text--secondary']"
          :disabled="controlDisabled"
          class="ml-n1 mr-1"
          size="28"
        >
          {{ icon }}
        </v-icon>
        <div
          :class="[sidebar ? 'text-body-2' : 'text-body-1', hasError ? 'error--text': (controlSuccessMessage ? 'success--text' : 'text--secondary'), {'text--disabled': controlDisabled}]"
          class="mr-auto text-truncate"
        >
          {{ label }}
        </div>
        <v-tooltip
          bottom
          :disabled="!changeButtonTooltip"
        >
          <template #activator="{ on: onTooltip }">
            <div
              class="d-inline-block"
              v-on="onTooltip"
            >
              <v-btn
                v-if="listRequestParameter"
                :disabled="controlDisabled || requestableLoading || controlReadonly"
                color="primary"
                small
                text
                @click.stop="menuOpen = true"
              >
                {{ $t('general.buttons.change') }}
              </v-btn>
            </div>
          </template>
          <span>{{ changeButtonTooltip }}</span>
        </v-tooltip>
      </v-card-title>

      <slot
        v-if="showValues.length"
        :items="showValues"
        name="list"
      >
        <object-list-view
          :value="showValues"
          :indent="indent"
          :divider="divider"
        >
          <template #list-item="{_item, _cssClass, _itemClass, _indent, _itemLink, _skeleton, _disabled}">
            <slot
              name="list-item"
              :item="_item"
              :css-class="_cssClass"
              :item-class="_itemClass"
              :indent="_indent"
              :item-link="_itemLink"
              :skeleton="_skeleton"
              :disabled="_disabled"
            />
          </template>
        </object-list-view>
      </slot>
      <slot
        v-else-if="$scopedSlots.empty"
        name="empty"
      />

      <v-progress-linear
        v-if="requestableLoading && !sidebar"
        height="2"
        indeterminate
      />
      <v-divider
        v-if="!requestableLoading && !outlined && !sidebar"
        :class="[{'error--text': hasError}]"
        class="custom-object-control__divider"
      />
    </v-card>
    <template
      v-if="sidebar"
      #append
    >
      <v-progress-linear
        v-if="requestableLoading"
        absolute
        bottom
        height="2"
        indeterminate
      />
    </template>
  </v-input>
</template>
